/* Estilos personalizados para las flechas del carrusel */
.video-player{
    width: 100%;
}
/* Estilos personalizados para las flechas del carrusel */
.carousel.carousel-slider .control-arrow {
    background-size: cover; /* Ajusta el tamaño del fondo para cubrir el área */
    width: 50px;
    height: 90px;
    top: 40%;
    bottom: 0;
    margin-top: 0;
    padding: 5px;
    
  }
  
