.table-container {
    margin: 20px 0;
  }
  
  .table-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  tfoot .total-row td {
    font-weight: bold;
    background-color: #f9f9f9;
  }
  