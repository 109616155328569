/*-------------- ESTILO BLOQUEADO -------------------------*/
.service-card.blocked {
  cursor: not-allowed; /* Cambiar el cursor cuando esté bloqueada */
  background-color: #e0f2e9; /* Cambiar el color de fondo a verde */
  opacity: 0.6; /* Reducir la opacidad para indicar que está bloqueada */
}

.service-card.blocked button {
  pointer-events: none; /* Deshabilitar los eventos de clic en el botón */
}

