/* cotizador.css */

/* --------------------Estilos para el contenedor principal-------------------- */
.cotizador-container {
    display: flex;
    flex-direction: column; /* Alinear los elementos en columna */
    align-items: center; /* Centrar horizontalmente los elementos */
  }
  
  /* --------------------Estilos para el banner -----------------------------*/
  .banner-cotizador {
    width: 100%;
    height: 50vh; /* Altura del banner al 50% de la altura de la pantalla */
    overflow: hidden; /* Oculta el contenido que se desborda del banner */
  }
  
  .banner-cotizador img {
    width: 100%;
    height: 100%; /* Ajustar la imagen al tamaño del contenedor */
    object-fit: cover; /* Escala la imagen para que cubra todo el contenedor sin deformarse */
    object-position: center bottom; /* Posición de la imagen centrada en la parte inferior */
  }
  .banner-cotizador .text-container {
    position: absolute;
    bottom: 40vh; /* Alinea el contenedor de texto en la parte inferior */
    left: 0; /* Alinea el contenedor de texto a la izquierda */
    padding: 10px; /* Ajusta el relleno según sea necesario */
    color: #fff; /* Color del texto */
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
  }
  
  .text-container h3 {
    font-family: 'Montserrat', sans-serif; /* Aplica la fuente Cocogoose */
    font-weight: bold;
    color: antiquewhite;
    margin-bottom: 4px;
  }
  .text-container h4 {
    font-family: 'Montserrat', sans-serif; /* Aplica la fuente Cocogoose */
    color: antiquewhite;
  }
  
  /*-------------------- Estilos para el contenedor del contenido principal y la barra lateral --------------------*/
  .content-wrapper {
    width: 100%;
    /*max-width: 1200px;  Ancho máximo para el contenido */
    display: flex;
    flex-wrap: wrap; /* Permitir el ajuste de los elementos en la siguiente línea */
    justify-content: center; /* Centrar los elementos horizontalmente */
  }
  
    
    /*---------------------------------- ESTILO DE SIDEBAR Y COMPONENTES INTERNOS -------------------------------*/
    /* Ajustes para la sidebar */
  .sidebar {
    width: 500px;
    min-height: 700px;
    padding: 10px;
    background-color: #000000;
    display: flex;
    flex-direction: column; /* Cambia la dirección de la distribución a vertical */
    position: relative;
    transition: width 0.3s ease;
  }

  /* Estilo para controlar la expanción del sidebar */
  .sidebar.collapsed {
    width: 50px; /* Ancho cuando está contraído */
  }
  
  .sidebar.expanded {
    width: 300px; /* Ancho cuando está expandido */
  }
  
  .imgagen-sidebar,
  .contador {
    display: none; /* Ocultar contenido por defecto */
  }
  
  .sidebar.expanded .imgagen-sidebar,
  .sidebar.expanded .contador {
    display: block; /* Mostrar contenido solo cuando está expandido */
  }
  
  .btnContraer {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fff; /* Color de fondo del botón */
    border: none; /* Sin borde */
    padding: 10px; /* Relleno interno */
    cursor: pointer; /* Cursor de mano al pasar por encima */
    border-radius: 5px; /* Bordes redondeados */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Sombra para darle un poco de profundidad */
    transition: background-color 0.3s ease; /* Transición para el cambio de color */
  }
  
  .btnContraer:hover {
    background-color: #f0f0f0; /* Color de fondo al pasar por encima */
  }
  
  .btnContraer i {
    font-size: 20px; /* Tamaño del icono */
    color: #333; /* Color del icono */
  }
  
  /* Estilos para el contador y sus componentes internos */
  .contador {
    width: 100%;
    display: flex;
    flex-direction: column; /* Cambia la dirección de la distribución a vertical */
    align-items: center;
  }
  
  /* Ajustes para el botón de enviar */
  .contador button {
    margin-top: 10px; /* Añade un espacio entre el contador y el botón */
  }
  .contador p{
    color: #dfdfe7;
  }
  .datosPasoUno{
    text-align: justify;
  } 
    /*---------------------------------- ESTILO DE MAIN Y COMPONENTES INTERNOS ----------------------------------*/
    .main-content {
      flex: 1;
      padding: 10px;
      background-color: #dfdfe7;
    }
    
    .cajaCrear {
      margin-bottom: 20px;
    }
    
    label {
      display: block;
      margin-bottom: 5px;
    }
    .main-content p{
      color: #000000;
    }
  
    /*--------------------------- BOTÓN VISTA PREVIA COTIZADOR ---------------------------------------*/
  
  /* Estilo para el botón flotante */
  .boton-preview-cotizar {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 30px;
    font-size: 16px;
    font-family: 'Calibri', sans-serif; /* Aplica la fuente Calibri */
    text-align: center;
    text-decoration: none;
    background-color: aliceblue;
    border: 1px solid #457a7c;
    color: #457a7c;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: fixed; /* Fija la posición del botón */
    bottom: 20px; /* Posición desde el borde inferior */
    right: 20px; /* Posición desde el borde derecho */
    z-index: 999; /* Z-index para estar por encima del contenido */
  }
  
  .boton-preview-cotizar {
    background-color: #457a7c;
    color: white;
  }

  /*-------------- ESTILO DE CAJAS CONTENEDOR , EX CAJA CREAR EN APP.CSS --------*/
  .cajaCotizador{
    background-color: #dfdfe7;
    align-items: center;
    padding: 20px 90 90 90;
  }  