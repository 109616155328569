.contenedorFechas {
  padding-top: 20px;
  padding-bottom: 30px;
  display: flex;
  flex-direction:row; /* Cambia la dirección de la distribución a vertical */
  justify-content: space-around; /* Espacio uniforme alrededor de los elementos */
  align-items: center; /* Centra horizontalmente los elementos */
}

.fecha,
.numeroEquipo {
  display: flex;
  flex-direction: column; /* Cambia la dirección de la distribución a vertical */
  align-items: flex-start; /* Alinea los elementos a la izquierda */
}

.btn-siguiente {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 10px; /* Agrega un espacio entre el último elemento y el botón */
}

.btn-siguiente:hover {
  background-color: #0056b3;
}
