.locacion-seleccionada {
    /* Estilos para el contenedor principal */
    margin-bottom: 20px; /* Espaciado inferior entre secciones */
  }
  
  /*--------------------------------- BANNER ----------------------------------*/
  .locacion-seleccionada img {
    /* Estilos para la imagen de portada */
    width: 100%;
    height: auto;
    /* Otros estilos que desees agregar */
  }
  .banner {
  position: relative;
}

.banner .text-container {
  position: absolute;
  text-align: justify;
  padding: 3px 19px;
  bottom: 1px;
  left: 1px;
  color: rgb(0, 0, 0); /* Color del texto */
  background-color: #0000005d ;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Sombra del texto */
}
.text-container h3 {
  font-family: 'Montserrat', sans-serif; /* Aplica la fuente Cocogoose */
  font-weight: bold;
  color: antiquewhite;
  margin-bottom: 4px;
}
.text-container h4 {
  font-family: 'Montserrat', sans-serif; /* Aplica la fuente Cocogoose */
  color: antiquewhite;
}

/*---------------------------- CARRIL DE IMAGENES ------------------------------*/

/* Ajustes para mostrar tres imágenes a la vez */
.imagenes {
  display: flex;
  overflow-x: auto;
  gap: 5px;
  scroll-snap-type: x mandatory; /* Establece el desplazamiento horizontal suave */
}

/* Cambiar el color de la barra de desplazamiento en navegadores webkit (Chrome, Safari) */
.imagenes::-webkit-scrollbar {
  width: 10px; /* Ancho de la barra de desplazamiento */
}

.imagenes::-webkit-scrollbar-track {
  background-color: #3b383859; /* Color de fondo de la barra de desplazamiento */
}

.imagenes::-webkit-scrollbar-thumb {
  background-color: #000000; /* Color del cursor de la barra de desplazamiento */
  border-radius: 5px; /* Borde redondeado para el cursor */
}


/* Contenedor que agrupa conjuntos de tres imágenes */
.conjunto-imagenes {
  display: flex;
  gap: 10px;
  scroll-snap-align: start; /* Alineación del desplazamiento */
}

.clickeable {
  pointer-events: auto; /* Permite eventos de clic para las imágenes clickeables */
  user-select: auto; /* Permite la selección de texto para las imágenes clickeables */
}


.imagenes img {
  flex: 0 0 calc(11% - 10px); /* Ancho para mostrar tres imágenes con un espacio entre ellas */
  width: 100px; /* Ancho deseado */
  height: auto; /* Alto deseado */
  object-fit: cover;
  cursor: pointer;
}

.imagen:hover {
  opacity: 0.8;
}

/* Estilo para el icono que indica más imágenes a la derecha */
.indicador-scroll {
  display: none; /* Inicialmente oculto */
  position: absolute;
  right: -20px; /* Posición a la derecha del carril de imágenes */
  transform: translateY(-50%);
  font-size: 24px; /* Tamaño del ícono */
  cursor: pointer;
}

/*--------------------------- BOTÓN COTIZADOR ---------------------------------------*/

/* Estilo para el botón flotante */
.boton-cotizar {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 30px;
  font-size: 16px;
  font-family: 'Calibri', sans-serif; /* Aplica la fuente Calibri */
  text-align: center;
  text-decoration: none;
  background-color: aliceblue;
  border: 1px solid #000000;
  color: #000000;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: fixed; /* Fija la posición del botón */
  bottom: 20px; /* Posición desde el borde inferior */
  right: 20px; /* Posición desde el borde derecho */
  z-index: 999; /* Z-index para estar por encima del contenido */
}

.boton-cotizar:hover {
  background-color: #000000;
  color: white;
}

  
/* --------------------------Estilos para los tres divs -----------------------------*/

/* Estilos para la seccion2 */
.seccion2 {
  margin-top: -90px;
  display: flex; /* Usa flexbox para organizar los elementos en línea */
  justify-content: center; /* Centra los elementos horizontalmente */
  gap: 100px; /* Espacio de 15px entre los elementos */
  padding: 100px; /* Agrega un relleno para separar del borde */

  /* Añade más estilos si es necesario para la sección 2 */
  
}

/* Estilos para .descripcion, .informacion-locacion y .caracter-locacion */
.descripcion,
.informacion-locacion{
  font-family: 'Calibri', sans-serif; /* Aplica la fuente Calibri */
  text-align: justify; /* Justificar el texto */
  align-items: center;
  flex-basis: calc(44.44% - 5px); /* Ancho del 44.44% para cada elemento, con espacio de 5px entre ellos */
}
.informacion-locacion button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 30px;
  margin-left: 10px;
  font-family: 'Calibri', sans-serif; /* Aplica la fuente Cocogoose */
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  background-color: aliceblue;
  border: 1px solid #000000; /* Color del borde */
  color: #000000; /* Color del texto */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.informacion-locacion button:hover{
  background-color: #000000; /* Color de fondo al pasar el mouse */
  color: white; /* Cambia el color del texto al pasar el mouse */
}
.caracter-locacion {
  font-family: 'Calibri', sans-serif; /* Aplica la fuente Cocogoose */
  text-align: justify;
  padding: 30px;
  background-color: #000000; /* Color de fondo */
  color: rgb(0, 0, 0); /* Color del texto */
  border-radius: 10px; /* Bordes redondeados */
}
.caracter-locacion p{
  color: #f5f5f7;
}
.caracter-locacion h4{
  color: #f5f5f7;
}


/* Estilos para la seccion 3*/
.seccion3{
  .tag {
    /* Estilos para los tags */
    font-family: 'Calibri', sans-serif; /* Aplica la fuente Cocogoose */
    margin-right: 5px; /* Espacio entre los tags */
    padding: 3px 8px;
    background-color: #AF7C58; /* Color de fondo */
    color: rgb(0, 0, 0); /* Color del texto */
    border-radius: 10px; /* Bordes redondeados */
  }
}

/* Estilos para la seccion4 */
.seccion4 {
  margin-top: -90px;
  display: flex; /* Usa flexbox para organizar los elementos en línea */
  justify-content: center; /* Centra los elementos horizontalmente */
  gap: 25px; /* Espacio de 15px entre los elementos */
  padding: 100px; /* Agrega un relleno para separar del borde */
  /* Otros estilos que desees agregar */
}

/* Estilos para .ubicacion y .rutas */
.ubicacion,
.rutas {
  flex: 1; /* Distribuye el espacio disponible entre .ubicacion y .rutas */
  text-align: justify; /* Justificar el texto */
  /* Otros estilos que desees agregar */
}
.rutas p{
  padding-left: 50px;
  font-family: 'Calibri', sans-serif; /* Aplica la fuente Cocogoose */
  font-size: medium;
  margin-top: 1%;
  margin-bottom: 1%;
}
/* Estilos para el mensaje como burbuja de información */
.mensaje {
  background-color:#00000064; /* Fondo blanco con nivel de transparencia */
  border-radius: 10px; /* Bordes redondeados */
  padding: 7px; /* Espaciado interno */
}

.mensaje p {
  font-style: italic; /* Texto en cursiva */
  font-size: small;
  align-content: center;
}

/* Estilos específicos para mapComponent */
.ubicacion .mapComponent {
  width: 100%; /* O el ancho deseado */
  height: 100%; /* O la altura deseada */
}

/* Estilos para la seccion 5*/
.seccion5 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Espaciado entre elementos */
  padding: 40px; /* Agrega un relleno para separar del borde */
  font-family: 'Calibri', sans-serif; /* Aplica la fuente Cocogoose */
}

/* Estilos para estacionamiento y servicios-basicos, refugio-bodega-basecamp, y comunicaciones */
.estacionamiento,
.servicios-basicos,
.refugio-bodega-basecamp,
.comunicaciones {
  background-color: #000000; /* Fondo blanco con nivel de transparencia */
  border-radius: 10px; /* Bordes redondeados */
  padding: 17px; /* Espaciado interno */
  width: 35%; /* Ajusta el ancho según necesites */
  text-align: justify; /* Justificar el texto */
  display: flex; /* Utiliza flexbox para alinear verticalmente */
  flex-direction: column; /* Alinea el contenido en columnas */
  justify-content: center; /* Centra verticalmente el contenido */
}

/* Estilos para cada uno de los párrafos dentro de los contenedores */
.estacionamiento p,
.servicios-basicos p,
.refugio-bodega-basecamp p,
.comunicaciones p {
  color: #f5f5f7;
  margin: 0px 0px 0 50px; /* Ajusta el margen para separar los párrafos */
  text-align: justify; /* Justificar el texto */
}

/* Estilos para la seccion 6*/
/* Estilos para el botón cuadrado */

/* Estilos para alinear los botones horizontalmente */
/* Estilos para la sección 6 */
.seccion6 {
  display: flex;
  justify-content: center;
  gap: 20px; /* Espaciado entre elementos */
}

/* Estilos para el contenedor de botones */
.vestuario {
  display: flex;
  align-items: center;
}

/* Estilos para los botones */
.btn-ropa button {
  width: 80px; /* Ancho deseado */
  height: 80px; /* Alto deseado */
  border-radius: 9%;
  background-color: #AF7C58; /* Color de fondo */
  margin-right: 60px;
  /* Añade otros estilos según tus preferencias */
}

/* Asegurarse de que el último botón no tenga margen derecho para evitar espacio adicional */
.btn-ropa:last-child {
  margin-right: 0;
}

/* Ajusta el tamaño del texto dentro del botón */
.btn-ropa button {
  font-size: 40px; /* Tamaño de la fuente */
  /* Otros estilos de texto si los necesitas */
}
.seccion7 {
  display: flex;
  justify-content: center;
  margin-top: -50px;
  gap: 20px; /* Espaciado entre elementos */
}

/* Estilos para la seccion 7*/
.recomendacion{
  background-color:#000000; /* Fondo blanco con nivel de transparencia */
  border-radius: 10px; /* Bordes redondeados */
  padding: 17px; /* Espaciado interno */
  margin: 90px;
  font-family: 'Calibri', sans-serif; /* Aplica la fuente Cocogoose */
  color: #f5f5f7;
  text-align: justify;
}
.recomendacion p{
  color: #f5f5f7;
}

.seccionDescargo {
  display: flex;
  justify-content: right;
}
.usoImgTrue{
  background-color:#000000; /* Fondo blanco con nivel de transparencia */
  border-radius: 8px; /* Bordes redondeados */
  color: #f5f5f7;
  margin-top: 10px;
  font-size: .8rem;
}
.usoImgTrue p{
  color: #f5f5f7;
  padding: 10px;
}
.usoImgTrue a {
  color: #ff0000; /* Cambiar el color del enlace */
}
.usoImgTrue a:hover {
  color: #00ff00; /* Cambiar el color al pasar el mouse sobre el enlace */
}

.usoImgFalse{
  color: #f5f5f7;
  margin-top: 10px;
  font-size: .8rem;
}
.usoImgFalse p{
  color: #000000;
  padding: 10px;
}

/*------------------ ESTILO MOVILES ------------------------*/

@media (max-width: 768px){

 /* Estilos para el contenedor de texto del banner */
.banner .text-container {
  display: none;
}

/* Estilos para los títulos dentro del contenedor de texto */
.text-container h3 {
  font-family: 'Montserrat', sans-serif; /* Aplica la fuente Cocogoose */
  font-weight: bold;
  color: antiquewhite;
  margin-bottom: 2px; /* Reducir el margen inferior para dispositivos móviles */
}

.text-container h4 {
  display: none;
}


/* Estilos para el botón flotante */
.boton-cotizar {
  display: none; /* Oculta el botón en dispositivos móviles */
}

/* Estilos para los elementos en la sección 2 */
.seccion2 {
  display: block; /* Cambia a display:block en dispositivos móviles */
  padding: 20px;
  margin-top: 10px;
}

.descripcion,
.informacion-locacion {
  flex-basis: 100%; /* Ancho completo para dispositivos móviles */
}

.informacion-locacion button {
  margin-top: 15px; /* Espaciado superior para dispositivos móviles */
  margin-left: 0; /* Elimina el margen izquierdo para dispositivos móviles */
}

/* Estilos para la sección 3 */
.seccion3 {
  padding: px 10px;
  margin-left: 10px;
  margin-right: 10px;
  
}
.tag {
  display:inline-block;
}

/* Estilos para la sección 4 */
.seccion4 {
  display: block; /* Cambia a display:block en dispositivos móviles */
  padding: 20px; /* Ajusta el relleno para dispositivos móviles */
}

.ubicacion,
.rutas {
  width: 100%; /* Ancho completo para dispositivos móviles */
}

.rutas p {
  padding-left: 0; /* Elimina el relleno izquierdo para dispositivos móviles */
  margin-left: 0; /* Elimina el margen izquierdo para dispositivos móviles */
}

/* Estilos para la sección 5 */
.seccion5 {
  display: block; /* Cambia a display:block en dispositivos móviles */
  padding: 20px; /* Ajusta el relleno para dispositivos móviles */
}

/* Estilos para los elementos en la sección 5 */
.estacionamiento,
.servicios-basicos,
.refugio-bodega-basecamp,
.comunicaciones {
  width: 100%; /* Ancho completo para dispositivos móviles */
  margin-bottom: 20px; /* Espaciado inferior para dispositivos móviles */
}

/* Estilos para la sección 6 */
.seccion6 {
  display: block; /* Cambia a display:block en dispositivos móviles */
  padding: 20px; /* Ajusta el relleno para dispositivos móviles */
}

/* Estilos para el contenedor de botones */
.vestuario {
  flex-direction: row; /* Cambia la dirección de la columna en dispositivos móviles */
  justify-content: space-between;
}

/* Estilos para los botones */
.btn-ropa button {
  margin-right: 0; /* Elimina el margen derecho para dispositivos móviles */
  margin-bottom: 15px; /* Espaciado inferior para dispositivos móviles */
}

/* Estilos para la sección 7 */
.seccion7 {
  display: block; /* Cambia a display:block en dispositivos móviles */
  padding: 20px; /* Ajusta el relleno para dispositivos móviles */
}

/* Estilos para el contenedor de recomendación */
.recomendacion{
  background-color:#000000; /* Fondo blanco con nivel de transparencia */
  border-radius: 10px; /* Bordes redondeados */
  padding: 10px; /* Espaciado interno */
  margin: 10px;
  font-family: 'Calibri', sans-serif; /* Aplica la fuente Cocogoose */
  color: #f5f5f7;
  text-align: justify;
}
.recomendacion p{
  color: #f5f5f7;
}

.seccionDescargo {
  display: block;
  justify-content: right;
}
.usoImgTrue{
  background-color:#000000; /* Fondo blanco con nivel de transparencia */
  border-radius: 8px; /* Bordes redondeados */
  margin: 10px;
  padding-bottom: 1px;
  padding: 10px;
  font-family: 'Calibri', sans-serif; /* Aplica la fuente Cocogoose */
  color: #f5f5f7;
  text-align: justify;
}
.usoImgTrue p{
  text-align: justify;
  color: #f5f5f7;
}
.usoImgTrue a {
  color: #ff0000; /* Cambiar el color del enlace */
}
.usoImgTrue a:hover {
  color: #00ff00; /* Cambiar el color al pasar el mouse sobre el enlace */
}

.usoImgFalse{
  margin: 10px;
  padding-bottom: 1px;
  padding: 10px;
  font-family: 'Calibri', sans-serif; /* Aplica la fuente Cocogoose */
  color: #f5f5f7;
  text-align: justify; 
}
.usoImgFalse p{
  text-align: justify;
  color: #000000;
}


}