  .etiqueta-proxima{
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
  }
  