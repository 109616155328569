// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
@import "variables.scss";

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/helpers";

@import "../../node_modules/bootstrap/scss/accordion";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/offcanvas";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/placeholders";
@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/type";


// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../../node_modules/bootstrap/scss/utilities/api";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


/*App.css*/

@import "footer.scss";

.App {
  text-align: center;
}
/* fonts.css */

.fs-7{
    font-size: .8rem;
}

@font-face {
  font-family: 'Cocogoose'; /* Nombre de la fuente */
  src: local('Cocogoose'), url('/src/fonts/Cocogoose.ttf') format('truetype'); /* Ruta a tu archivo de fuente */
}
@font-face {
    font-family: 'Cocogoose-Regular'; /* Nombre de la fuente */
    src: local('Cocogoose-regular'), url('/src/fonts/Cocogoose-Regular.ttf') format('truetype'); /* Ruta a tu archivo de fuente */
}
@font-face {
    font-family: 'Cocogoose-Light'; /* Nombre de la fuente */
    src: local('Cocogoose-light'), url('/src/fonts/Cocogoose-Light.ttf') format('truetype'); /* Ruta a tu archivo de fuente */
}
@font-face {
  font-family: 'Montserrat'; /* Nombre de la fuente */
  src: local('Montserrat'), url('./fonts/Montserrat.otf') format('truetype'); /* Ruta a tu archivo de fuente */
  /* Agrega las diferentes fuentes (woff, woff2, etc.) para compatibilidad con varios navegadores */
}
@font-face {
  font-family: 'Calibri'; /* Nombre de la fuente */
  src: local('Calibri'), url('./fonts/Calibri.ttf') format('truetype'); /* Ruta a tu archivo de fuente */
  /* Agrega las diferentes fuentes (woff, woff2, etc.) para compatibilidad con varios navegadores */
}

h1, h2, h3, h4, h5 {
    font-family: 'Cocogoose-light', sans-serif; /* Aplica la fuente Cocogoose */
    strong{
        font-family: 'Cocogoose', sans-serif; /* Aplica la fuente Cocogoose */
    }
}

.navbar{
    font-family: 'Cocogoose-regular', sans-serif; /* Aplica la fuente Cocogoose */
    font-weight: bolder;
    text-transform: uppercase;
    .icons{
        font-family: arial;
    }
    .navbar-brand{
        img{
            padding: 10px 0;
        }
    }
    .navbar-nav{
        .nav-link{
            color: $white;
            &.active{
                color: $primary;
            }
        }
    }
    .navbar-toggler{
        color: $black;
    }
}
.seccionCarousel{
    position: relative;
    margin-top: 100px;
    max-height: 1200px;
    width: 100%;
    overflow-y: hidden;
    @include media-breakpoint-up(xl) {
        margin-top: 0;
    }
    .boxTitle{
        z-index: 11;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        top: 0;
        background-color: rgba(0, 16, 25, 0.5);
        h1{
            color: $white;
            text-transform: uppercase;
            text-align: left;
            font-family: 'Cocogoose-light', sans-serif; /* Aplica la fuente Cocogoose */
            strong{
                font-family: 'Cocogoose-regular', sans-serif; /* Aplica la fuente Cocogoose */
            }
            @include media-breakpoint-up(lg) {
                font-size: 4rem;
            }
            @include media-breakpoint-up(xl) {
                font-size: 5rem;
            }
        }
        a{
            font-family: 'Cocogoose-regular', sans-serif; /* Aplica la fuente Cocogoose */
        }
        @media (max-width: 575.98px) {
            position: relative;
            background: transparent;
            h1{
                color: $black;
                margin-top: 20px;
                text-align: center;
            }
            .btn{
                width: 100%;
            }
        }
    }
}

.carousel{
    z-index: 10;
    background: $black;
}
.carousel-control-prev, .carousel-control-next{
    bottom: 40%;
    top: 40%;
    background: $black;
    width: 10%;
    @media (max-width: 767.98px) {
        top: 5%;
        bottom: 70%;
    }
}

.locations{
    background: $gray-300;
    overflow: hidden;
    a{
        font-family: 'Cocogoose-regular', sans-serif; /* Aplica la fuente Cocogoose */
    }
}
.carousel-caption{
    width: 100%;
    background: rgb(0, 16, 25, 0.4);
    right: 0;
    left: 0;
    bottom: 0;
    text-align: left;
    @extend .p-4;
    @media (max-width: 767.98px) {
        position: relative;
        background: $black;
    }
    h4,strong{
        font-family: 'montserrat' !important;
    }
}

.icon-box{
        width: 60px;
        height: 60px;
        padding-top: 10px !important;
        font-size: 1.6rem !important;
        i{
            padding-top: 0px;
        }
}

.local{
    background: $black;
    color: white;
    ul{
        list-style: none;
        padding: 0;
        font-family: 'Cocogoose-light', sans-serif; /* Aplica la fuente Cocogoose */
        font-size: 1.4rem;
        line-height: 3rem;
        @extend .py-md-5;
        li{
            @extend .py-2
        }
    }
}
.fixers{
    h1{
        font-size: 3.5rem;
        text-align: center;
    }
    h4{
        text-align: center;
        font-family: 'Cocogoose-regular', sans-serif; /* Aplica la fuente Cocogoose */
        text-transform: uppercase;
        font-size: .8rem;
        padding-top: 10px;
    }
    a{
        font-family: 'Cocogoose-regular', sans-serif; /* Aplica la fuente Cocogoose */
    }
}
.contacto{
    background: tint-color($black, 15%);
    color: $white;
}

.card{
    .card-img-overlay{
        background: rgb(0, 16, 25, 0.4);
        bottom: 0;
        top: auto;
        border-radius: 0;
        h5{
            font-size: 1rem;
            color: $white;
            font-family: 'montserrat', sans-serif; /* Aplica la fuente Cocogoose */
        }
    }
}

.btn-summer{
    background-color: #FFD55F;
}
.btn-autumn{
    background-color: #A389F4;
}
.btn-winter{
    background-color: #85D7FF;
}
.btn-spring{
    background-color: #FF7CE5;
}


.progress-bar {
    margin: 0;
    padding: 0;
    position: relative;
    flex-direction: unset;
    justify-content: left;
}
  
.progress-step {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $gray-600;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;
    z-index: 1; /* Ensure the steps are above the lines */
    float: left;
    margin-right: 10px;
    font-weight: 800;
}
.progress-step.completed {
    background-color: $black;
    color: $white;
}
.progress-step.current {
    background-color: $primary;
}
.progress-step.completed::before {
    background-color: $primary;
}

.react-datepicker-wrapper{
    display: block;
}

.header-cotizar{
    position: fixed;
    top: 100px;
    z-index: 10;
    background: rgb(255, 255, 255, 0.85);
    .bi{
        font-style: normal;
        &:after{
            content: 'Cotizar';
            margin-left: 10px;
            font-family: 'Cocogoose-light', sans-serif;
            font-size: 1.2rem;
            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
    }
}
.mt-100{
    margin-top: 175px;
}

.admin-container{
    min-height: 81vh;
}
.pointer{
    cursor: pointer;
}
.dashed{
    border-style: dashed;
}

.bannerServices{
    background: $white;
    .card{
        min-height: auto;
        background: $light;
        @media (min-width: 992px) {
            min-height: 210px;
        }
        @media (min-width: 1200px) {
            min-height: 180px;
        }
        @media (min-width: 1400px) {
            min-height: 160px;
        }
        p{
            margin-bottom: 0;
        }
    }
}
.swal2-title{
    font-family: "montserrat";
    font-weight: 500;
    font-size: 1.4rem;
    @extend .p-5;
}

.swal2-actions{
    .swal2-styled{
        @extend .btn;
        @extend .rounded-pill;
    }
    .swal2-confirm{
        @extend .btn-primary;
    }
    .swal2-cancel{
        @extend .btn-secondary;
    }   
}