.blog-vista {
    display: flex; /* Dividirá la vista en dos contenedores flexibles */
  }
  
  .blog-vista-imagen {
    flex: 1; /* El primer contenedor ocupará la mitad del espacio */
  }
  
  .blog-vista-informacion {
    flex: 1; /* El segundo contenedor ocupará la otra mitad del espacio */
    padding: 20px; /* Agrega un poco de espacio alrededor del contenido */
  }
  
  .blog-vista-titulo {
    font-size: 34px; /* Establece el tamaño del título */
  }
  
  .blog-vista-fecha {
    font-size: 16px; /* Establece el tamaño de la fecha */
    color: #fff;
  }
  
  .blog-vista-contenido {
    padding: 20px; /* Agrega espacio alrededor del contenido */
    font-size: 18px; /* Establece el tamaño del contenido */
    color: #fff;
  }
  