/* blog.css */
.blog-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .blog-entry {
    display: flex;
    flex-direction: column;
    background-color: #f7f7f7;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .blog-entry-image {
    height: 500px; /* Establece la altura de la imagen */
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
    padding: 10px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro para el título */
  }
  
  .blog-entry-title {
    margin: 0;
    font-size: 38px;
    font-weight: bold
  }
  
  .blog-entry-content {
    padding: 10px;
  }
  
  /* Estilos adicionales según tus preferencias */
  