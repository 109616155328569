.imagen-preview {
    width: 200px; /* Tamaño deseado para las imágenes */
    height: auto; /* Mantener la proporción */
    margin-bottom: 10px; /* Espaciado entre imágenes */
  }
  
.imagen-preview-portada {
    width: 400px; /* Tamaño deseado para las imágenes */
    height: auto; /* Mantener la proporción */
    margin-bottom: 10px; /* Espaciado entre imágenes */
  }
  .componentes-principales{
  background-color: #ffffff;
  padding-bottom: 0px; /*SE QUITA EL PADDING PARA EVITAR UN ESPACIO VACIO EN LA PAGINA PRINCIPAL (revisar todos los compontentes)*/
}
