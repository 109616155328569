/*------------------ ESTILO GENERAL ----------------------*/

.banner-nosotros {
    width: 100%;
    height: 50vh; /* Altura del banner al 50% de la altura de la pantalla */
    overflow: hidden; /* Oculta el contenido que se desborda del banner */
  }
  
  .banner-nosotros img {
    width: 100%;
    height: 100%; /* Ajustar la imagen al tamaño del contenedor */
    object-fit: cover; /* Escala la imagen para que cubra todo el contenedor sin deformarse */
    object-position: center 70%; /* Posición de la imagen centrada en la parte inferior */
  }
  .banner-nosotros .text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #ffff;
  }
  .text-container h3 {
    font-family: 'Montserrat', sans-serif; /* Aplica la fuente Cocogoose */
    font-weight: bold;
    color: #ffff !important;
    margin-bottom: 4px;
    font-size: 50px;
  }
  .text-container h4 {
    font-family: 'Montserrat', sans-serif; /* Aplica la fuente Cocogoose */
    color: #ffff;
  }

.secciones{
    margin-top: 20px;
    margin-left: 90px;
    margin-right: 90px;
}
.secciones p {
    font-size: 23px ; /* Tamaño de fuente para el texto */
    margin-bottom: 10px; /* Espacio inferior del texto */
    color: #000000;
    text-align: justify;
  }
.contenidos-mision{
  margin-top: 30px;
}
.contenidos-vision{
  margin-top: 30px;
}
.contenidos-equipo{
  margin-top: 30px;
  margin-bottom: 100px;
}

/* ---------------------- ESTILO MOVILES -------------------- */

@media (max-width: 768px) {

  /*------------------ ESTILO GENERAL ----------------------*/

/* Estilos para el banner */
.banner-nosotros {
  width: 100%;
  height: 50vh; /* Altura del banner al 50% de la altura de la pantalla */
  overflow: hidden; /* Oculta el contenido que se desborda del banner */
}

.banner-nosotros img {
  width: 100%;
  height: 100%; /* Ajustar la imagen al tamaño del contenedor */
  object-fit: cover; /* Escala la imagen para que cubra todo el contenedor sin deformarse */
  object-position: center 70%; /* Posición de la imagen centrada en la parte inferior */
}

.text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #ffff;
}

.text-container h3 {
  font-family: 'Montserrat', sans-serif; /* Aplica la fuente Cocogoose */
  font-weight: bold;
  color: #ffff !important;
  margin-bottom: 4px;
  font-size: 30px; /* Tamaño de fuente reducido para dispositivos móviles */
}

.text-container h4 {
  font-family: 'Montserrat', sans-serif; /* Aplica la fuente Cocogoose */
  color: #ffff;
}

/* Estilos para las secciones */
.secciones {
  margin-top: 20px;
  margin-left: 20px; /* Reducción del margen izquierdo */
  margin-right: 20px; /* Reducción del margen derecho */
}

.secciones p {
  font-size: 18px; /* Tamaño de fuente reducido para dispositivos móviles */
  margin-bottom: 10px; /* Espacio inferior del texto */
  color: #000000;
  text-align: justify;
}

/* Estilos adicionales para las diferentes secciones */
.contenidos-mision,
.contenidos-vision,
.contenidos-equipo {
  margin-top: 30px;
  margin-bottom: 50px; /* Reducción del margen inferior */
}

}
